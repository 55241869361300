import React from "react";

interface Props {
  fill: string;
  width: number | string;
  height: number | string;
}

const BarChart: React.FC<Props> = (props) => (
  <svg
    width={props.width}
    height={props.height}
    viewBox="0 0 10 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.00016 0.666626C1.17697 0.666626 1.34654 0.736864 1.47157 0.861888C1.59659 0.986912 1.66683 1.15648 1.66683 1.33329V10.6666C1.66683 10.8434 1.59659 11.013 1.47157 11.138C1.34654 11.2631 1.17697 11.3333 1.00016 11.3333C0.823352 11.3333 0.653783 11.2631 0.528758 11.138C0.403734 11.013 0.333496 10.8434 0.333496 10.6666V1.33329C0.333496 1.15648 0.403734 0.986912 0.528758 0.861888C0.653783 0.736864 0.823352 0.666626 1.00016 0.666626ZM9.00016 3.33329C9.17697 3.33329 9.34654 3.40353 9.47157 3.52855C9.59659 3.65358 9.66683 3.82315 9.66683 3.99996V10.6666C9.66683 10.8434 9.59659 11.013 9.47157 11.138C9.34654 11.2631 9.17697 11.3333 9.00016 11.3333C8.82335 11.3333 8.65378 11.2631 8.52876 11.138C8.40373 11.013 8.3335 10.8434 8.3335 10.6666V3.99996C8.3335 3.82315 8.40373 3.65358 8.52876 3.52855C8.65378 3.40353 8.82335 3.33329 9.00016 3.33329ZM5.00016 5.99996C5.17697 5.99996 5.34654 6.0702 5.47157 6.19522C5.59659 6.32025 5.66683 6.48981 5.66683 6.66663V10.6666C5.66683 10.8434 5.59659 11.013 5.47157 11.138C5.34654 11.2631 5.17697 11.3333 5.00016 11.3333C4.82335 11.3333 4.65378 11.2631 4.52876 11.138C4.40373 11.013 4.3335 10.8434 4.3335 10.6666V6.66663C4.3335 6.48981 4.40373 6.32025 4.52876 6.19522C4.65378 6.0702 4.82335 5.99996 5.00016 5.99996Z"
      fill={props.fill}
    />
  </svg>
);

export default BarChart;
